import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="change-method"
export default class extends Controller {
  connect() {
    console.log('change-method' )
  }

  delete(elem) {
    let form = elem.target.closest('form')
    // elem.preventDefault()
    console.log('change-method#delete', form)
    let method_field = form.querySelector('input[name="_method"]')

    if (method_field) {
      method_field.value = 'delete'
    }
  }
}
