<template>
  <div class="history">
    <h1>Historie</h1>
    <div v-if="quizes.length > 0">
      <p class="text">
        Sie haben bereits {{ quizes.length }} Quize(s) gemacht.
      </p>
      <ul>
        <li
          v-for="quiz in last_quizes"
          :key="quiz.id"
          @click="$emit('switch-history', quiz.id)"
          :class="{ text: true, active: selected_quiz_id === quiz.id }"
          class="rounded"
        >
          <arrow-right /> {{ end_date(quiz) }}
        </li>
      </ul>
    </div>
    <div v-else>
      <p>Aktuell haben Sie noch kein Quiz abgeschlossen.</p>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import ArrowRight from "../components/arrow-right.vue"

export default {
  props: ["quizes", "selected_quiz_id"],
  methods: {
    end_date(quiz) {
      if (quiz.ended_at) {
        return moment(String(quiz.ended_at)).format("DD.MM.YYYY - HH:mm ")
      } else {
        return "Noch offen"
      }
    },
  },
  computed: {
    last_quizes() {
      // console.log( this.quizes.indexOf( this.quizes[3]))
      return this.quizes.filter(q => this.quizes.indexOf(q) < 7)
    }
  },
  components: {
    ArrowRight,
  },
}
</script>

<style lang="scss" scoped>
.history {
  // font-size: 1em;
  // line-height: 2rem;
  // margin-bottom: 30px;

  h1 {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 1.3rem;
    // font-weight: bold;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      padding: 5px 7px;
      margin-bottom: 4px;
      border: 1px solid #e7ede8;
      &:hover,
      &.active {
        background-color: #75cdec;
        cursor: pointer;
      }
    }
  }
}
</style>
