import { Controller } from "@hotwired/stimulus"
// import Rails from "@rails/ujs"
import TournamentAdmin from "../channels/tournament_admin_channel"

console.log('tournament-admin laoded')

// Connects to data-controller="tournament-admin"
export default class extends Controller {
  connect() {
    console.log('tournament-admin controller')
  }
}
