import consumer from "./consumer"

document.addEventListener( 'turbo:load', () => {
  const body = document.querySelector("body")
  let account_id = body.dataset.currentUser
  let grade_id = body.dataset.currentGrade
  let lesson_id = body.dataset.currentLesson
  let schedul_id = body.dataset.currentSchedul
  let subject_id = body.dataset.currentSubject
  let issue_id = body.dataset.currentIssue

  window.App.ping = consumer.subscriptions.create({
    channel: 'PingChannel',
    account_id: account_id
  }, {
    connected() {
      // console.log('>>>>>> ping_channel connected', account_id)
    },

    disconnected() {
      // console.log('ping_channel disconnected', account_id)
    },

    received(response) {
      console.log('ping_channel got response:', response)
      switch (response.mode) {
        case 'message':
          if (response?.data && response.data.length > 0) {
            let message_id = response.data[0].id
            fetch(`/messages/${message_id}`, {
              responseKind: "turbo-stream",
              headers: {
                Accept: "text/vnd.turbo-stream.html",
              },
            })
            .then((r) => r.text())
            .then((html) => Turbo.renderStreamMessage(html))
          }
          break;
        case 'poll':
          console.log(response.data)
          if (response?.data && response.data.length > 0) {
            let poll_id = response.data.id
            fetch(`/polls/${poll_id}`, {
              responseKind: "turbo-stream",
              headers: {
                Accept: "text/vnd.turbo-stream.html",
              },
            })
              .then((r) => r.text())
              .then((html) => Turbo.renderStreamMessage(html))
          }
          break;
      }
    },

    i_am_alive: function() {
      // console.log('ping_channel i_am_alive with data:', data)
      if( !issue_id || !lesson_id) return

      return this.perform('i_am_alive', {
        lesson_id: lesson_id,
        schedul_id: schedul_id,
        issue_id: issue_id
      });
    },

    check_for_new_poll() {
      return this.perform('check_for_new_poll', {
        grade_id: grade_id,
        subject_id: subject_id
      });
    },

    check_for_new_message() {
      // console.log('check for message')
      return this.perform("check_for_new_message", {
        grade_id: grade_id,
        subject_id: subject_id,
      })
    }
  });

})
