<template>
  <div class="questions">
    <!-- {{ questions }} -->

    <div
      v-for="question in questions"
      :key="question.id"
      class="question"
    >
      <h2 class="question-header">{{ question.title }}</h2>
      <pre v-if="false">
        {{ question }}
      </pre>
      <p v-if="(question.description && !question.question_type.startsWith('fillgap-'))">
        {{ question.description }}
      </p>

      <checkbox-component
        v-if="question.question_type == 'checkbox'"
        :quiz_done="quiz.ended_at !== null"
        :question="question"
      />
      <radio-component
        v-if="question.question_type == 'radio'"
        :quiz_done="quiz.ended_at !== null"
        :question="question"
      />
      <input-component
        v-if="question.question_type == 'input'"
        :quiz_done="quiz.ended_at !== null"
        :question="question"
      />
      <sort-component
        v-if="question.question_type == 'sort'"
        :quiz_done="(quiz.ended_at !== null)"
        :question="question"
        @selected-answers="get_answers"
      />
      <fill-gap-component
        v-if="question.question_type.startsWith('fillgap-')"
        :quiz_done="(quiz.ended_at !== null)"
        :question="question"
        :answers="answers"
      />
    </div>
  </div>
</template>

<script>
import CheckboxComponent from "../components/checkbox.vue"
import RadioComponent from "../components/radio.vue"
import InputComponent from "../components/input.vue"
import SortComponent from "../components/sort.vue"
import SelectComponent from "../components/select.vue"
import FillGapComponent from "../components/fill-gap.vue"

export default {
  emits: ['selected-answers'],
  props: ['quiz', 'questions', 'answers'],
  components: {
    CheckboxComponent,
    RadioComponent,
    InputComponent,
    SortComponent,
    SelectComponent,
    FillGapComponent
  },
  methods: {
    get_answers(answers) {
      // console.log('>> gotten answers:', answers)
      this.$emit('selected-answers', answers)
    }
  }
}
</script>
