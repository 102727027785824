import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="switch-day-night"
export default class extends Controller {
  connect() {
    this.theme = window.localStorage.getItem('theme')
    this.theme ||= 'light'
    console.log('switch-day-night', this.theme)
    this.setTheme()
  }

  toggle(event) {
    if (this.theme == 'dark') {
      this.theme = 'light'
      event.target.classList.remove("fa-sun")
      event.target.classList.add("fa-moon")
    } else {
      this.theme = 'dark'
      event.target.classList.add("fa-sun")
      event.target.classList.remove("fa-moon")
    }
    this.setTheme()
  }

  setTheme() {
    window.localStorage.setItem("theme", this.theme)
    document.getElementsByTagName(
      "html"
    )[0].dataset.bsTheme = this.theme
  }
}
