import { Controller } from "@hotwired/stimulus"
import TurbolinksAdapter from "vue-turbolinks"
import { createApp, h } from "vue"

import App from "../quiz-frontend/quiz.vue"

// Connects to data-controller="frontend-quiz-loader"
export default class extends Controller {
  connect () {
    // console.log('************** frontend-quiz-loader', this.element.id, this.element, this.element.dataset)
    const params = this.element.dataset
    createApp(App, { ...params })
      .use(TurbolinksAdapter)
      .mount(`#${ this.element.id }`)
  }
}
