<template>
  <div class="mb-3">
    <div v-for="answer in random_answers"
         :key="answer.id"
         class="answer d-flex align-items-baseline">

      <button
        @click="mark(answer)"
        style="background-color: transparent; border-color: transparent !important;"
        :disabled="quiz_done">
        <svg xmlns="http://www.w3.org/2000/svg"
             width="20"
             viewBox="0 0 24 24"
             :style="{
               fill: answer_wrong(answer) ? 'red' : '',
               fill: answer_class(answer) ? 'green' : '',
              }"
             :class="{ status: true && quiz_done,
                       would_have_been_right: answer_would_have_been_right( answer ) && quiz_done,
                       right: answer_class( answer ) && quiz_done,
                       wrong: answer_wrong( answer ) && quiz_done,
                     }">
          <path v-if="answer.answer == answer.id || ( quiz_done && ( answer_class(answer) || answer_wrong(answer) ) )"
                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z">
          </path>
          <path v-else
                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z">
          </path>
        </svg>
      </button>
      <span @click="mark(answer)" style="cursor: pointer;">{{ answer.question }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'question', 'quiz_done' ],
  computed: {
    random_answers () {
      let x = this.question.answers
        .map(a => ({sort: Math.random(), value: a}))
        .sort((a, b) => a.sort - b.sort)
        .map(a => a.value)
      return this.quiz_done ? this.question.answers : x
    }
  },
  methods: {
    mark(answer) {
      this.question.answers.forEach(a => a.answer = null)
      answer.answer = answer.id
    },
    answer_would_have_been_right(answer) {
      return (answer.correct_answer === 't' && answer.answer !== answer.correct_answer)
    },
    answer_correct(answer) {
      return (answer.correct_answer === 't' && answer.answer === answer.correct_answer)
    },
    answer_wrong(answer) {
      return (answer.correct_answer === 'f' && answer.answer === 't')
    },
    answer_text(answer) {
      return this.answer_correct(answer) ? ' ' : ' '
    },
    answer_class(answer) {
      return this.answer_correct(answer)
    },
  },
  mounted () {
    let answer = this.question.answers.find(a => a.answer == a.id )
    if( answer && answer.id ) {
      this.question.value = answer.id
    }
  }
}
</script>

<style lang="scss" scoped>
.answer {
  position: relative;
  margin-bottom: 5px;

  .status {
    width: 20px;
    height: 20px;
    fill: lightgrey;
  }
  .right {
    fill: #18b708;
  }
  .wrong {
    fill: #d5423f;
  }
  .would_have_been_right {
    fill: rgb(27, 147, 216);
  }
}
</style>
