import consumer from "./consumer"
import { post, get } from "@rails/request.js"

export default consumer.subscriptions.create("TournamentAdminChannel", {
  current_timer: null,
  active_students: [],

  initialized() {
    console.log('tournament_admin initialized.')

    var self = this
    setInterval(function() {
      // console.log('setInterval check active accounts', self.active_students)
      self.active_students.forEach( (as) => {
        if (Date.now() - as.last_update > 30000) {
          document
            .getElementById(`active_student_${as.account_id}`)
            .classList.remove("text-success", "fw-bold")
        }
      })
    }, 5055)
  },

  connected() {
    console.log('teacher is connecting ...')
  },

  disconnected() {
    clearInterval(this.current_timer)
  },

  async received(data) {
    console.log('tournament_admin received', data)
    switch (data.modus) {
      case 'confirm':
        let students = document.getElementById("tournament-question-student-list")?.querySelectorAll('li')
        // console.log(students)
        if (students) {
          Array.from(students).forEach( (e) => {
            if (e.dataset.studentId == data.account_id) {
              e.classList.add('fw-bold')
            }
          })
        }
        break

      case 'ping':
        let as = this.active_students.find((a) => a.account_id == data.account_id)
        if (as) {
          as.last_update = Date.now()
        } else {
          this.active_students.push({
            account_id: data.account_id,
            last_update: Date.now(),
          })
        }
        let a = document.getElementById(`active_student_${data.account_id}`)
        if (a) a.classList.add("text-success", "fw-bold")

        a = document.querySelector(`#select-student option[value='${data.account_id}']`)
        if (a) a.innerHTML = data.student_select
        break;

      default:
        fetch(
          `/polls/${data.poll_id}/display_tournament_question?index=${
            data.quiz_question
          }`,
          {
            responseKind: "turbo-stream",
            headers: {
              Accept: "text/vnd.turbo-stream.html",
            },
          }
        )
        .then((r) => r.text())
        .then((html) => Turbo.renderStreamMessage(html))
        // .then((_) => history.replaceState(history.state, "", href))

        break;
    }
  }
});
