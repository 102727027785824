<template>
  <div>
    <div class="answer">
      <input name="quiz.id" :disabled="quiz_done" v-model="question.answers.input">
      <span v-if="quiz_done" 
            :class="{ status: true, 
                      right: answer_correct( question.answers ),
                      wrong: ! answer_correct( question.answers ),
                    }">
        {{ answer_text( question.answers ) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'question', 'quiz_done' ],
  methods: {
    answer_correct ( answer ) {
      return answer.input === answer.correct_answer
    },
    answer_wrong ( answer ) {
      return ! this.answer_correct( answer )
    },
    answer_text ( answer ) {
      return this.answer_correct( answer ) ? 'richtig' : 'falsch'
    },
  },
}
</script>

<style lang="scss" scoped>
  .answer {
    position: relative;
    margin-bottom: 5px;
    
    .status {
      display: inline-table;
      position: absolute;
      top: 0; right: 0;
      color: white;
      font-size: 1.2rem;
      border-radius: 10px;
      padding: 2px 8px;
      background-color: lightgrey;
    }
    .right {
      background-color: #18b708;
    }
    .wrong {
      background-color: #d5423f;
    }
    .would_have_been_right {
      background-color: lightgray;
    }
  }
</style>
