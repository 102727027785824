<template>
  <div class="new_quiz_button">
    <p class="text mt-1">
      Sie können in einer Aufgaben jederzeit ein Quiz durchführen.
      Es ist Ihnen auch möglich dieses Quiz zu wiederholen wenn Ihr Ergebnis
      nicht gut ist oder Sie Ihr Wissen vertiefen wollen.
    </p>
    <button type="button" @click="$emit( 'new_quiz' )" class="btn btn-info">Neues Quiz?</button>
  </div>
</template>

<script>
  export default {
  }
</script>
  
<style lang="scss" scoped>
  
</style>
