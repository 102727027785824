import { Controller } from "@hotwired/stimulus"
import { post, get } from "@rails/request.js"

String.prototype.replaceAt = function(index, replacement) {
  return (
    this.substring(0, index) +
    replacement +
    this.substring(index + replacement.length)
  )
}

// Connects to data-controller="video"
export default class extends Controller {
  video = null
  video_tracker = null
  video_container = null
  played_duration = 0.0
  last_timestamp = 0
  bitmap = ''
  percent = 0.0
  cnt = 0
  last_pos = 1
  observer = null

  static values = {
    issueContentId: String,
    lessonId: String,
    url: String
  }

  async connect() {
    this.video = this.element
    this.video_container = this.video.closest('.issue.exercise')

    let url = `${this.urlValue}?lesson_id=${this.lessonIdValue}`

    console.log('connect video ici:', this.issueContentIdValue, 'li:', this.lessionIdValue, url)
    // console.log('!!! PARENT', this.element.closest('.issue.exercise') )

    if (this.lessonIdValue == null) return

    await get(url)
    .then(response => response.json)
    .then(response => {
      console.log('old bitmap found', response)
      if (response && response?.bitmap !== null && response?.bitmap !== 'null') {
        this.bitmap = response.bitmap
        this.percent = response.percent
      } else {
        this.bitmap = ''
        this.percent = 0
      }
    })

    this.observer = new IntersectionObserver((this.check_viewport), {
      root: document.getElementById('container'),
      threshold: 0.5
    })

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio !== 1 && !this.element.paused) {
            this.element.pause()
          } else if (this.element.paused) {
            // this.element.play()
          }
        })
      },
      { threshold: 0.4 }
    )

    this.observer.observe(this.element)

    // console.log('result 1 bitmap', this.bitmap)
    this.bitmap = this.bitmap + '0'.repeat(Math.abs( Math.floor(this.video.duration - this.bitmap.length)) )
    // console.log('result 1 bitmap', this.bitmap)

    this.video_tracker = document.getElementById(`video-tracker-${this.issueContentIdValue}`)
    this.reflect_percentage()
    this.add_handler()
  }

  check_viewport(entries, observer) {
    console.log('observer called')
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        let elem = entry.target;

        if (entry.intersectionRatio >= 0.75) {
          intersectionCounter++;
        }
      }
    });
  }

  add_handler() {
    // console.log( 'video duration', this.video.duration )
    // console.log( 'video currentTime', this.video.currentTime )
    // console.log( 'video playbackRate', this.video.playbackRate )

    this.video.onplay = (evt) => {
      // console.log("play", this.video.currentTime, this.percent )
      this.last_timestamp = Math.floor(evt.timeStamp / 1000)
    }

    this.video.onended = (evt) => {
      if (this.cnt > 0 ) this.send_data()
    }

    this.video.ontimeupdate = (evt) => {
      const pos = Math.floor(this.video.currentTime) - 1
      if (this.last_pos === pos) return

      let b = this.bitmap.charAt(pos)
      b = b.match(/^[0-9]$/) ? parseInt(b) + 1 : 1

      this.bitmap = this.bitmap.replaceAt( pos, String(b)[0] )
      let len = this.bitmap.match(/[^0]/g).length
      if (len === NaN || len === undefined) len = 0
      const seen = len

      this.percent = Math.round(seen / this.video.duration * 100 )
      this.percent = this.percent > 100 ? 100 : this.percent

      this.video_tracker.innerHTML = `Angesehen: ${this.percent}%`

      this.reflect_percentage()

      if( this.cnt > 10) {
        this.cnt = 0
        this.send_data()
      } else { this.cnt++ }

      this.last_pos = pos
    }

    this.video.onpause = (evt) => {
      this.cnt = 0
      this.send_data()
    }

    this.video.onseeking = function(evt) {
      // console.log("onseeking", evt )
    }
    this.video.onseeked = function(evt) {
      // console.log("onseeked", evt )
    }
    this.video.onratechange = function(evt) {
      // console.log("ratechange", evt )
    }
  }

  reflect_percentage() {
    let color = ''
    let border = 0
    switch (true) {
      case this.percent < 20: border = 10; color = '#9a2323'; break;
      case this.percent < 40: border = 10; color = '#fc0303'; break;
      case this.percent < 60: border = 10; color = '#f56060'; break;
      case this.percent < 80: border = 10; color = '#ddbb26'; break;
      case this.percent < 90: border = 10; color = '#54ed34'; break;
      default: border= 10; color = '#237e05'
    }
    this.video.style.borderStyle = 'solid'
    this.video.style.borderColor = color
    this.video.style.borderWidth = `${border}px`
    if (border > 0) {
      this.video.style.borderColor = color
    }
    // console.log('video', this.video.style)
  }

  send_data() {
    let form = new FormData()
    form.append("lesson_id", this.lessonIdValue)
    form.append("issue_content_id", this.issueContentIdValue)
    form.append("bitmap", this.bitmap)
    form.append("percent", this.percent)

    post(this.urlValue, { body: form })
  }

}
