
import { Turbo } from "@hotwired/turbo-rails"
Turbo.start()
// Turbo.session.drive = true

Turbo.setProgressBarDelay(50)

// import Rails from "@rails/ujs"
// Rails.start()

window.App = {}
window.App.tournament = {}

import "@popperjs/core"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

import "../controllers"
import "../channels"
// const channels = import.meta.globEager("../channel/*_channel.js")

import 'trix'
import "@rails/actiontext"

// import mermaid from 'mermaid/dist/mermaid.esm.min.mjs'

// import { darkmode } from "./darkmode.min.js"
// window.darkmode = darkmode

import autosize from "autosize"

let interval_cnt = 1
let dropzone = null
let lastKey = null

import "../stylesheets/application.scss"

// document.addEventListener("turbo:reload", () => {
// console.log("turbo:reload")
// mermaid.initialize({
//   startOnLoad: true,
//   logLevel: "error", // [1]
//   securityLevel: "loose", // [2]
//   theme:
//     window.matchMedia &&
//     window.matchMedia("(prefers-color-scheme: dark)").matches
//       ? "dark"
//       : "dafault", // [3]
//   themeVariables: {
//     darkMode: "true",
//   },
// })
// })

function catchFind (evt) {
  if (evt.key == "Escape" && lastKey == "Escape") {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')
      .attributes.content.value
    lastKey = null

    Rails.ajax({
      url: "/search",
      type: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": csrfToken,
        Accept: "application/javascript",
      },
    })
  } else if (evt.key == "Escape" && lastKey == null) {
    lastKey = evt.key
  } else {
    lastKey = null
  }
}

document.addEventListener("turbo:load", () => {
  // console.log("turbo:load")

  window.setTimeout(function () {
    var alert = new bootstrap.Alert(".alert")
    if (Array.from(alert).length > 0) alert?.close()
  }, 4000)

  autosize(document.querySelectorAll("textarea"))

  // get search window with esc esc
  document.addEventListener("keydown", catchFind)

  // console.log('cookies', document.cookie.split(";"))
  if (document.cookie) {
    var tournament = App.tournament
    var account_id = document.head.querySelector("meta[name=current-user]")?.content

    if (!account_id) return

    decodeURIComponent(document.cookie)
      .split(";")
      .forEach((e) => {
        let cookie = e.split("=")
        if (cookie[ 0 ] == "modus" && cookie[ 1 ] == "start") {
          document
            .getElementById("tournament-container")
            .classList.remove("d-none")
          tournament.options.window_open = true
          tournament.options.account_id = account_id
        }
      })
  }

  document.getElementById('modal-window').addEventListener("shown.bs.modal", () => {
    document.querySelector("#modal-window input[autofocus]").focus()
  })

  interval_cnt = 0
  const form_fields = document.querySelectorAll(".dynamic-input-field")
  if (form_fields.length > 0) {
    ;[].forEach.call(form_fields, (elem) => {
      // console.log("dynamic-input-field ", elem);
      elem.addEventListener("enter", (evt) => {
        document.getElementById("search-form")?.submit()
      })
    })
  }

  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  )
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  )
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
})

document.addEventListener("turbo:render", () => {
  // console.log("turbo:render")
  autosize.update()
})
