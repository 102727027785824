import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="pageing"
export default class extends Controller {
  connect() {
    // console.log('pageing controller')
  }
  change(evt) {
    // console.log('changed', evt)
    // console.log('changed', evt.srcElement.value)
    let href = document.location.href
    let url = new URL(document.location.href)
    let search = url.searchParams
    search.set("per", evt.srcElement.value)
    search.set("t", Date.now())
    document.location.href = url.toString()
    // Rails.ajax({
    //   type: "GET",
    //   url: url.toString()
    // })

  }
}
