import { navigator } from "@hotwired/turbo"
import { Controller } from "stimulus"
// import { useMutation } from "stimulus-use"

export default class extends Controller {
  connect() {
    // this.observer = new MutationObserver((mutationsList) => {
    //   mutationsList.forEach((mutation) => {
    //     if (
    //       mutation.type === 'attributes' &&
    //       mutation.attributeName === 'src'
    //     ) {
    //       console.log(
    //         'history push',
    //         mutation,
    //         this.element.getAttribute('src')
    //       )
    //       history.pushState(
    //         { turbo_frame_history: true },
    //         '',
    //         this.element.getAttribute('src')
    //       )
    //     }
    //   })
    // })
    // this.observer.observe(this.element, { attributes: true })
    //
    // this.popStateListener = (event) => {
    //   console.log('history pop', event)
    //   if (event.state.turbo_frame_history) {
    //     console.log('history pop:', event)
    //     Turbo.visit(window.location.href, { action: 'replace'})
    //     // history.back()
    //   }
    // }
    // window.addEventListener("popstate", this.popStateListener)
  }

  disconnect() {
    this.observer?.disconnect()
    window.removeEventListener("popstate", this.popStateListener)
  }
}
