import { Controller } from "@hotwired/stimulus"
import autosize from "autosize"

// Connects to data-controller="control-task"
export default class extends Controller {
  static values = {
    id: String
  }

  connect() {
    console.log('connect control-task', this.idValue)
  }
  skip(evt) {
    evt.preventDefault();
    console.log('control-task skip', this.idValue)

    document.getElementById(`control_task_${this.idValue}`).remove()
    this.get_first_element_and_click()
  }
  ok_and_next(evt) {
    evt.preventDefault();

    console.log('control-task ok_and_next', evt.target.href)
    fetch(evt.target.href)
    .then((response) => {
      if (response.status == 200) {
        document.getElementById(`control_task_${this.idValue}`).remove()
        this.get_first_element_and_click()
      }
    })
  }

  get_first_element_and_click() {
    const list = document.querySelectorAll("#control-task-list")
    if (list && list[0]) {
      list[0].querySelector("a").click()
      document.getElementById("control_tasks_counter").innerHTML =
        document.querySelectorAll("#control-task-list .list-group-item")
                .length
    }
  }
}
