<template>
  <div class="mb-4">
    <div v-if="false" class="mb-4">
      <button @click="check_answers">check</button>
      <button @click="debug = !debug">debug</button>
    </div>

    <pre v-if="debug">
      {{ answers }}
    </pre>
    <pre v-if="false && debug">
      {{ rules }}
    </pre>
    <pre v-if="debug">
      {{ question }}
    </pre>

    <div id="tags-content">
      <p v-html="question_content"></p>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    // Markdown
  },
  props: [ 'question', 'quiz_done', 'answers' ],
  data() {
    return {
      debug: false
    }
  },
  computed: {
    question_answers() {
      if (!this.answers[this.question.id]) this.answers[this.question.id] = []
      return this.answers[this.question.id]
    },
    filled_answers() {
      return this.question_answers.filter(e => e.id)
    },
    rules() {
      if (this.question.rules) {
        return this.question.rules
      } else {
        return []
      }
    },
    disabled() {
      return ( this.quiz_done == true ? 'disabled' : '' )
    },
    question_content () {
      var question_text = this.question.markdown_description
      const tags = question_text.match(/\{(.+?)\}/g).map((e) => e.replace(/[{}]/g, ''))
      tags.forEach((tag,idx) => {
        // console.log('question_content ', tag)
        let option_tag = ''
        let error = true
        this.random_answers(tag).forEach(a => {
          option_tag += `<option ${a.position > 0 ? 'selected' : ''} value="${a.id}" data-text="${a.answer}">${a.answer}</option>`
          if (a.position > 0 && a.correct_answer == 't') error = false
        })

        let select_tag = `<select ${this.disabled} class="tag form-select ${error ? 'bg-danger' : ''}" id="tag_${tag}" name="${tag}"><option value="">---</option>`
        select_tag += option_tag
        select_tag += "</select>"

        question_text = question_text.replace(RegExp("{"+tag+"}"), select_tag)
      });
      return question_text
    }
  },
  methods: {
    random_answers(name) {
      return this.question
                 .answers
                 .filter(a => a.question == name)
                 .map(a => ({sort: Math.random(), value: a}))
                 .sort((a, b) => a.sort - b.sort)
                 .map(a => a.value)
    },
    get_answer_name(id) {
      const answer = this.question.answers.find(e => e.id == id)
      if (answer) { return answer.answer }
      else { return null }
    },
    change_field( e, name, value ) {
      // console.log(e, name, value)
      let answer = this.question_answers.find(a => a.name == name)
      if (answer) {
        // console.log('found answer', answer, e.name, e.value)
        answer.id = value
        answer.text = this.get_answer_name(value)
      } else {
        // name = select name
        // id = option id
        // text = option text / title
        this.question_answers.push({
          name: name,
          id: value,
          text: this.get_answer_name(value)
        })
      }
      e.classList.remove('bg-danger')
      if (e.value == '') e.classList.add('bg-danger')

    },
    // check_answers() {
    //   if (!this.answers[this.question.id]) this.answers[this.question.id] = []
    //
    //   this.rules.forEach(r => {
    //     // rules:
    //     //   source_field
    //     //   modus
    //     //   target_fields: []
    //
    //     let error = false
    //     let modus = r.modus
    //     let fields = r.target_fields
    //     let values = []
    //
    //     // find names of option instead of id's. because there are the same
    //     // names but with other id's for each select tag
    //     fields.forEach(f => {
    //       values.push(this.question_answers.find(a => a.name == f)['text'] )
    //     })
    //     console.log('values', values, values.length)
    //
    //     let size = new Set(values).size
    //     if (modus == 'uniq') {
    //       console.log('find uniq')
    //       if (size !== values.length) error = true
    //     } else {
    //       if (size === values.length) error = true
    //     }
    //     if (error) {
    //       fields.forEach(f => {
    //         document.getElementById(`tag_${f}`).classList.add('bg-danger')
    //       })
    //     }
    //   })
    // }
  },
  mounted () {
    let tags_content = document.getElementById('tags-content')
    let tags = tags_content.querySelectorAll('select.tag')

    tags.forEach( t =>
      t.addEventListener('change', () => this.change_field(t, t.name, t.value))
    )
  },
  unmounted () {
    let tags_content = document.getElementById('tags-content')
    let tags = tags_content.querySelectorAll('select.tag')

    tags.forEach( t =>
      t.removeEventListener('change', () => this.change_field(t, t.name, t.value))
    )
  }
}
</script>

<style lang="scss">
#tags-content { margin-right: 20px; }
select.tag.form-select {
  padding: 7px 10px 4px;
  width: max-content;
  min-width: 70px;
  display: inline !important;
}
</style>
