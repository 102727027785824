<template>
  <div v-if="quiz_done" class="d-flex gap-4 justify-content-between align-items-stretch mb-5">
    <div>
      <h1>Ihre Antwort</h1>
      <div v-for="answer in given_answers"
           :key="answer.id"
           class="answer-item"
           :style="{
             'border-left': answer.position != answer.correct_position ? '5px solid red' : ''
           }">
        {{ answer.question }}
      </div>
    </div>
  </div>

  <div v-if="!quiz_done" class="d-flex gap-4 justify-content-between align-items-stretch mb-5">
    <div class="w-100">
      <h1>Ihre Antworten</h1>
      <p>Ziehen Sie die Texte/Bilder von der rechten Seite in diesen Bereich.</p>
      <draggable
        class="question-target"
        v-model="selected_answers"
        @change="send_change"
        group="answers"
        item-key="id">
        <template #item="{ element, idx }">
          <div class="answer-item">{{ element.question }} {{ idx }}</div>
        </template>
      </draggable>

      <pre v-if="false"> {{question.answers}} </pre>
      <pre v-if="false"> {{answers}} </pre>
      <pre v-if="false"> {{given_answers}} </pre>

    </div>

    <div class="vr"></div>

    <div class="w-100">
      <h1>Die vorhandenen/möglichen Einträge</h1>
      <draggable
        class="question-source"
        v-model="random_answers"
        group="answers"
        item-key="id">
        <template #item="{ element, idx }">
          <div class="answer-item">{{ element.question }} {{ idx }}</div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  emits: ['selected-answers'],
  props: {
    quiz_done: { type: Boolean },
    question: { type: Object },
    answers: { type: [Object,Array] }
  },
  components: {
    draggable
  },
  data() {
    return {
      selected_answers: [],
      random_answers: []
    }
  },
  methods: {
    send_change(elem) {
      this.$emit('selected-answers', this.selected_answers)
      //if (elem?.added) {
      //  console.log('added to selected:', elem?.added)
      //}
    },
    randomize_answers() {
      let x = this.question
                  .answers
                  .map(a => ({ sort: Math.random(), value: a }))
                  .sort((a, b) => a.sort - b.sort)
                  .map(a => a.value)
      this.random_answers = x
    },
  },
  computed: {
    given_answers() {
      return this.question
                 .answers
                 .sort((a,b) => a.position - b.position)
    }
  },
  mounted() {
    this.randomize_answers()
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 20px;
}
.answer-item {
  padding: 10px 10px;
  background-color: #fff;
  margin-bottom: 4px;
  cursor: pointer;
}
.question-target,
.question-source {
  padding: 4px;
  min-height: 50px;
  border: 1px dashed darkgray;
  padding-bottom: 50px;
}
</style>
