import consumer from "./consumer"

document.addEventListener("turbo:load", () => {
  let account_id = document.querySelector("body").dataset.currentUser

  window.App.tournament = consumer.subscriptions.create(
    {
      channel: "TournamentChannel",
      account_id: account_id,
    },
    {
      options: {
        window_open: false,
        poll_id: null,
        poll_session: null,
        account_id: null,
        quiz_question: null,
      },

      connected() {
        console.log("tournament_channel connected")
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      ping(action) {
        console.log("tournament_channel ping")

        if (!this.options.window_open) return
        if (!this.options.account_id) return

        this.perform("ping", {
          modus: action ? action : "ping",
          account_id: this.options.account_id,
          quiz_question: this.options.quiz_question,
          window_open: this.options.window_open,
        })
      },

      submit_result(data) {
        let d = { ...data, ...this.options }
        d = { ...d, ...document.getElementById("tournament-container").dataset }
        d.result = true
        this.send(d)
      },

      received(data) {
        console.log("++++ tournament_channel received", data)

        var el = document.getElementById("tournament-container")
        if (!el) return

        if (data.options) {
          this.options = { ...options }
        }

        switch (data.modus) {
          case "start":
            el.dataset.account_id = this.options.account_id = account_id

            if (data.account_id && data.account_id != this.options.account_id) {
              return
            }

            this.options.window_open = true
            el.dataset.poll_id = this.options.poll_id = data["poll_id"]
            el.dataset.poll_session = this.options.poll_session =
              data["poll_session"]

            document.cookie = "modus=start;"
            document.getElementsByTagName("body")[0].style.overflow = "hidden"
            document
              .getElementById("tournament-container")
              .classList.remove("d-none")
            if (!data.view) {
              document.getElementById("tournament-content").innerHTML =
                '<p style="height: min-content; display: block; margin: auto;">Bitte haben Sie etwas Geduld.<br>Wir starten in wenigen Momenten</p>'
            }
            break
          case "stop":
            this.options.window_open = false

            this.options.poll_id = null
            this.options.poll_session = null
            this.options.account_id = null
            this.options.quiz_question = null

            document.cookie = "modus=stop;"
            document
              .getElementById("tournament-container")
              .classList.add("d-none")
            document.getElementsByTagName("body")[0].style.overflow = "auto"
            break
          case "display":
            // here comes a new question to display
            if (data.account_id && data.account_id != this.options.account_id) {
              return
            }

            this.options.poll_id = data["poll_id"]
            this.options.quiz_question = data["quiz_question"]
            this.options.poll_session = data["poll_session"]

            this.ping("confirm")
            break
          case "hide":
            console.log("got modus hide")
            break
          case "answer":
            console.log("got modus show answer")
            console.log(data)
            console.log(data.quiz_content.answers)
            data.quiz_content.answers.forEach((q, idx) => {
              var qb = document.getElementById(`answer_button_${idx}`)
              if (qb && q.a) {
                qb.classList.add("border", "border-5", "border-success")
              }
            })
            break
          default:
            break
        }

        if (data.view) {
          var content = document.getElementById("tournament-content")
          if (content) {
            content.innerHTML = data.view
            el.dataset.poll_id = this.options.poll_id = data["poll_id"]
            el.dataset.quiz_question = this.options.quiz_question =
              data["quiz_question"]
          }
        }
      },
    }
  )
})
