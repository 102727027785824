<template>
  <div class="mb-3">
    <div :disabled="quiz_done"
         v-for="answer in random_answers"
         :key="answer.id"
         class="answer d-flex align-items-baseline">

      <button :disabled="quiz_done"
              @click="mark(answer)"
              style="background-color: transparent; border-color: transparent !important;">
       <svg xmlns="http://www.w3.org/2000/svg"
            width="20"
            viewBox="0 0 24 24"
            :class="{
              status: true && quiz_done,
              would_have_been_right: answer_would_have_been_right( answer ) && quiz_done,
              right: answer_class( answer ) && quiz_done,
              wrong: answer_wrong( answer ) && quiz_done,
            }">
          <path v-if="marked(answer) || ( quiz_done && ( answer_class(answer) || answer_wrong(answer) ) )"
                d="M10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591zm-5.041-15c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z">
          </path>
          <path v-else
                d="M5 2c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z">
          </path>
        </svg>
      </button>

      <span @click="mark(answer)" style="cursor: pointer;">{{ answer.question }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['question', 'quiz_done'],
  computed: {
    random_answers() {
      let x = this.question.answers
        .map(a => ({ sort: Math.random(), value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map(a => a.value)
      return this.quiz_done ? this.question.answers : x
    },
  },
  methods: {
    log( answer ) {
      // console.log( answer )
    },
    mark(answer) {
      if( this.quiz_done ) return;

      if (answer.answer == null) { answer.answer = answer.id }
      else { answer.answer = null }
    },
    marked(answer) {
      return answer.answer == answer.id
    },
    answer_would_have_been_right ( answer ) {
      return ( answer.correct_answer === 't' && answer.answer !== answer.correct_answer )
    },
    answer_correct(answer) {
      return (
        answer.answer === answer.correct_answer && answer.correct_answer === "t"
      )
    },
    answer_wrong(answer) {
      return (
        answer.correct_answer !== answer.answer && answer.correct_answer === "f"
      )
    },
    answer_class(answer) {
      return this.answer_correct(answer)
    },
  },
  mounted() {
    this.question.value = this.question.answers
      .filter(a => a.answer === 't')
      .map(a => a.id)
  },
}
</script>

<style lang="scss" scoped>
.answer {
  position: relative;
  margin-bottom: 5px;

  .status {
    width: 20px;
    height: 20px;
    fill: lightgrey;
  }
  .right {
    fill: #18b708;
  }
  .wrong {
    fill: #d5423f;
  }
  .would_have_been_right {
    fill: rgb(27, 147, 216);
  }
}
</style>
