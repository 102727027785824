import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tournament"

let tournament = {}

export default class extends Controller {
  static targets = [ 'contentBlock', 'icon',
                     'destroy', 'contentContentBlock',
                     'contentCheckCriteria' ]

  connect() {
    console.log('connect tournament')
    tournament = {}
  }

  toggle(evt) {
    evt.preventDefault();
    console.log(
      "tournament: toggle",
      evt.target,
      evt.target.dataset,
      evt.target.id
    )

    let target = evt.target
    let data = target.dataset
    let id = target.id

    if (data.type == "radio") tournament = {}

    if ( tournament[id] && tournament[id].checked === "true" ) {
      data.checked = false
    } else {
      data.checked = true
    }
    tournament[id] = data

    target.classList.remove(
      "btn-primary",
      "btn-warning",
      "btn-outline-secondary"
    )
    if (tournament[id].checked == "true") {
      target.classList.add("btn-primary")
    } else {
      target.classList.add("btn-outline-secondary")
    }
  }

  submit_result(evt) {
    App.tournament.submit_result(tournament)
    evt.target.disabled = "disabled"
  }
}
