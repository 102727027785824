import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diary-feeling"
export default class extends Controller {
  static targets = [ 'emo' ]
  static values = {
    initial: Number,
    type: String,
    edit: Boolean,
    name: String
  }

  connect() {
    console.log('diary-feeling', this.typeValue, this.editValue, this.nameValue )
    this.set_color( this.initialValue )
  }

  update_feeling(elem) {
    if (!this.editValue) return

    this.set_color(elem.params.id)
    this.change_value(elem.params.id)
  }

  change_value(value) {
    let x = document.querySelector(`[name="journal[${this.nameValue}]"]`)
    if (x) x.value = value
  }

  set_color(value) {
    this.emoTargets.forEach(e => {
      e.classList.remove('text-info', 'text-success', 'text-danger')
    });

    let element = this.emoTargets.find(e => e.dataset.diaryFeelingIdParam == value)
    switch (value) {
      case 0:
        element.classList.add("text-info")
        break
      case 1:
        element.classList.add("text-success")
        break
      case 2:
        element.classList.add("text-danger")
        break
    }
  }
}
