<template>
  <div id="app">
    <pre v-if="false">
      <!-- {{ quizes }} -->
      <!-- {{ quiz_current }} -->
      -- {{ answers }} --
    </pre>

    <div v-if="!quiz_current && !quizes_done" class="row">
      <div class="col-sm-12">
        <new-quiz-button-component @new_quiz="new_quiz" />
      </div>
    </div>

    <div v-else class="row">

      <div v-if="quiz_current"
           :class="{
            'col-sm-8': quiz_current.ended_at,
            'col-sm-12': !quiz_current.ended_at
           }">
        <questions-view
          :quiz="quiz_current"
          :questions="shuffled_questions"
          :quiz_done="(quizes_done || !quiz_current)"
          :answers="answers"
          @selected-answers="get_answers"
        />
      </div>

      <div v-if="!quiz_current" class="col-sm-8">
        <new-quiz-button-component @new_quiz="new_quiz" />
      </div>

      <div v-if="(quizes_done || !quiz_current)" class="col-sm-4">
        <summary-view
          v-if="quizes_done.length > 0 && quiz_current.ended_at !== null"
          :quiz="quiz_current"
        />

        <history-view
          v-if="quizes_done.length > 0 && quiz_current.ended_at !== null"
          @switch-history="change_to_display_quiz"
          :quizes="quizes_done"
          :selected_quiz_id="selected_quiz_id"
        />
      </div>

      <div
        v-if="quiz_current && quiz_current.ended_at === null"
        class="col-sm-12"
      >
        <button class="btn btn-success" @click="submit_results">
          Antworten abgeben
        </button>
      </div>
      <div v-else class="col-sm-12">
        <new-quiz-button-component @new_quiz="new_quiz" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

var token = document.getElementsByName("csrf-token")[0].getAttribute("content")
axios.defaults.headers.common["X-CSRF-Token"] = token
axios.defaults.headers.common["Accept"] = "application/json"
axios.defaults.headers.common["Credentials"] = "same-origin"

import QuestionsView from "./views/questions.vue"
import SummaryView from "./views/summary.vue"
import HistoryView from "./views/history.vue"
import NewQuizButtonComponent from "./components/new_quiz_button.vue"

export default {
  components: {
    QuestionsView,
    SummaryView,
    HistoryView,
    NewQuizButtonComponent
  },
  props: ["focus", "id", "content"],
  data: function() {
    return {
      selected_quiz_id: null,
      answers: [],
      quizes: []
    }
  },
  methods: {
    get_answers(answers) {
      // console.log('>> gotten answers:', answers)
      this.answers = answers
    },
    find_quiz(quiz_id) {
      return this.quizes_done.find((q) => q.id === quiz_id)
    },
    change_to_display_quiz(quiz_id) {
      // console.log("change quiz to display ", quiz_id)
      let q = this.quizes_done.find((e) => e.id === quiz_id)
      if (q) this.selected_quiz_id = quiz_id
      // console.log( q )
    },
    request_quiz_data() {
      console.log( 'request quiz data' )
      axios
        .get("/api/v1/quizes/" + this.focus + "/" + this.id)
        .then((response) => {
          // console.log( 'api request ' + this.focus + '/' + this.id, response )
          // console.log( response.data )
          if (response.data && response.data.quizes.length > 0) {
            //
            // Sortieren der Quizes nach deren Abschlussdatum und Zeit
            //
            this.quizes = response.data.quizes.sort((a, b) => {
              let x = parseInt((a.ended_at + "").replace(/\D+/g, ""))
              let y = parseInt((b.ended_at + "").replace(/\D+/g, ""))
              // console.log( 'sort: ', a, b,  y - x )
              return y - x
            })
          } else {
            // console.log("request_quiz_data error while loading")
            // console.log(response.data)
            // console.log( response.data.quizes.length )
          }
        })
        .catch((error) => {})
    },
    submit_results() {
      // console.log('submit_results-----------------------')
      // console.log('quiz_current.questions', this.quiz_current.questions)
      // console.log('quiz_type', this.quiz_current.questions[0].question_type)

      var results = []
      var count = 0

      this.quiz_current.questions.forEach(question => {

        if (question.question_type.startsWith('fillgap')) {
          // console.log(question.question_type)
          // console.log('answer for question ', this.answers[question.id])

          results.push({ id: question.id,
                         result: this.answers[question.id].map(a => ({ id: a.id, position: 0, name: a.name }))
                       })
        }

        if (question.question_type == 'radio') {
          results.push({ id: question.id,
                         result: question.answers.filter(a => a.answer == a.id)
                                                 .map(a => ({ id: a.id, position: 0 }))})
          // count = results.length
        }

        if (question.question_type == 'checkbox') {
          results.push({ id: question.id,
                         result: question.answers.filter(a => a.answer == a.id)
                                                  .map(a => ({ id: a.id, position: 0}))})
          // count = this.quiz_current.questions.length
        }

        // FIXME: sort is not able to have more that one sort
        // per question!
        if (question.question_type == 'sort') {
          results.push({ id: question.id,
                         result: this.answers.map((a,idx) => ({ id: a.id, position: idx }))})
          // count = results.length
        }
      })

      // console.log('found results----------------------------')
      // console.log('result', results)
      // console.log('-----------------------------------------')

      this.selected_quiz_id = null

      // FIXME: die Anzahl Antworten sind nicht gleich der Anzahl Fragen die
      //    zu beantworten sind. Hier muss ein anderer Test her der die Fragen
      //    zu den Antworten zählt und dann entscheidet werden ob es genug sind.
      //    !! Checkboxen geben mehr Antworten als 1
      // if (results.length < count) {
      //   console.log( results, count )
      //   // alert("Bitte beantworten Sie alle Fragen.")
      //   // return false
      // }

      axios
        .put("/api/v1/quizes/" + this.quiz_current.id, { results: results })
        .then((response) => {
          // console.log( response )
          this.request_quiz_data()
        })
        .catch((error) => {})
    },
    new_quiz() {
      axios
        .get("/api/v1/quizes/" + this.focus + "/" + this.id + "/new")
        .then((response) => {
          // console.log( response.data )
          this.quizes = response.data.quizes
          // console.log( this.quizes )
          this.questions = this.quizes[0].questions
          this.answers = []
          // console.log( this.questions )
        })
        .catch((error) => {})
    },
  },
  computed: {
    shuffled_questions() {
      if( ! this.questions ) return
      return Object.values(this.questions).sort(() => 0.5 - Math.random())
    },
    quiz_current() {
      if (this.quizes.length <= 0) return []
      let q = this.quizes.find((e) => e.ended_at === null)
      // console.log( 'quiz_current active?: --------------------------', q )
      if (q) return q
      // console.log( 'quiz_current current_quiz_id?: --------------------------', q )
      if (this.selected_quiz_id) {
        q = this.find_quiz(this.selected_quiz_id)
        if (q) return q
      }
      if ((q = this.quizes_done[0])) {
        // console.log( 'quiz_current get_done: --------------------------', q )
        this.selected_quiz_id = q.id
        return q
      }
    },
    quizes_done() {
      if (this.quizes.length <= 0) return []
      return this.quizes.filter((e) => e.ended_at !== null)
    },
    questions() {
      return this.quiz_current.questions
    },
  },
  created() {
    // console.log( 'quiz created ...' )
  },
  mounted() {
    // console.log( 'quiz mounted ...' )
    // console.log( 'focus', this.focus, 'id', this.id, 'content', this.content )
    this.request_quiz_data()
  },
}
</script>

<style lang="scss" scoped>
mt-1 {
  margin-top: 10px;
}
</style>