import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="speech"
export default class extends Controller {
  static targets = [ 'button', 'content' ]

  isSpeaking = false
  buttonText = this.buttonTarget.innerHTML
  utterance = new SpeechSynthesisUtterance()

  preferredVoices = [
    "Google Deutsch",
    "Microsoft Katja Online (Natural) - German (Germany)",
    "Microsoft Hedda - German (Germany)",
    "Anna"
  ]

  connect () {
    console.log('speech connect', this.contentTarget.innerText)
  }

  disconnect () {
    this.stopSpeaking()
  }

  speak () {
    console.log('speak', this.isSpeaking)
    if (!this.isSpeaking) { this.startSpeaking() }
    else { this.stopSpeaking() }
  }

  loadVoices () {
    return new Promise((resolve) => {
      const voices = speechSynthesis.getVoices()

      if (voices.length > 0) {
        this.setPreferredVoice(voices)
        resolve()
      } else {
        speechSynthesis.onvoiceschanged = () => {
          const voices = speechSynthesis.getVoices()
          this.setPreferredVoice(voices)
          resolve()
        }
      }
    })
  }

  setPreferredVoice (voices) {
    const preferredVoice = this.preferredVoices
      .map(name => voices.find(v => v.name === name))
      .find(v => v !== undefined)

    if (preferredVoice) {
      this.utterance.voice = preferredVoice
      this.utterance.lang = preferredVoice.lang
      console.log("Selected voice:", preferredVoice.name)
      console.log("Selected lang:", preferredVoice.lang)
    }
  }

  makeChunksOfText (text) {
    const maxLength = 190
    let speechChunks = []

    // Split the text into chunks of maximum length maxLength without breaking words
    while (text.length > 0) {
      if (text.length <= maxLength) {
        speechChunks.push(text)
        break
      }

      let chunk = text.substring(0, maxLength + 1)

      let lastSpaceIndex = chunk.lastIndexOf(' ')
      if (lastSpaceIndex !== -1) {
        speechChunks.push(text.substring(0, lastSpaceIndex))
        text = text.substring(lastSpaceIndex + 1)

      } else {
        // If there are no spaces in the chunk, split at the maxLength
        speechChunks.push(text.substring(0, maxLength))
        text = text.substring(maxLength)
      }
    }

    return speechChunks
  }

  async playChunk (text) {
    this.utterance.text = text
    await speechSynthesis.speak(this.utterance)
  }

  async startSpeaking () {
    const speechChunks = this.makeChunksOfText(this.contentTarget.innerText)
    await this.loadVoices()

    this.isSpeaking = true
    this.buttonTarget.innerHTML = '<i class="fa fa-pause"></i>'

    for (let i = 0;i < speechChunks.length;i++) {
      await new Promise((resolve, reject) => {
        this.utterance.text = speechChunks[ i ]

        if (!this.isSpeaking) return

        speechSynthesis.cancel()
        speechSynthesis.speak(this.utterance)
        this.utterance.onend = () => { resolve() }
        this.utterance.onerror = (error) => { resolve() }
      })
    }
  }

  stopSpeaking () {
    console.log('stop')
    this.isSpeaking = false
    speechSynthesis.cancel()
    this.buttonTarget.innerHTML = this.buttonText
  }

  speakingEnded () {
    console.log('ended')
    this.isSpeaking = false
    this.buttonTarget.innerHTML = this.buttonText
  }
}
