import { Modal } from "bootstrap"
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal-window"
export default class extends Controller {
  connect() {
    // console.log("connected: modal-window", this)
    let backdrop = document.querySelector(".modal-backdrop")
    if (backdrop) { backdrop.remove() }
    this.modal = new Modal(this.element)
    this.modal.show()
    this.element.addEventListener("hidden.bs.modal", (event) => {
      this.remove()
    })
  }
  remove() {
    // this.modal.hide()
    document.body.style.overflow = ''

    let backdrop = document.querySelector(".modal-backdrop")
    if (backdrop) {
      console.log('modal-window#remove')
      backdrop.remove()
    }
  }

  disconnect() {
    let backdrop = document.querySelector(".modal-backdrop")
    if (backdrop) { backdrop.remove() }
  }
}
