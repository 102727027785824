<template>
  <div class="summary">
    <h1>Zusammenfassung</h1>
    <p class="text">Diese Angaben beziehen sich auf Ihr letztes durchgeführtes Quiz.</p>
    <svg>
      <defs>
        <linearGradient id="lg1" x1="0%" y1="0%" x2="100%" y2="100%" spreadMethod="pad">
          <stop offset="0%" stop-color="#0ea800" stop-opacity="1" />
          <stop offset="100%" stop-color="#61df1b" stop-opacity="1" />
        </linearGradient>
      </defs>
      <circle class="donut-hole" cx="50" cy="50" r="40" fill="transparent" />
      <circle
        class="donut-ring"
        cx="50"
        cy="50"
        r="40"
        fill="transparent"
        stroke="white"
        stroke-width="20"
      />
      <circle
        class="donut-segment"
        cx="50"
        cy="50"
        r="40"
        fill="transparent"
        xstroke="#49a943"
        stroke="url(#lg1)"
        stroke-width="15"
        :stroke-dasharray="percentage"
        stroke-dashoffset="0"
      />
    </svg>
    <div class="text">
      Ihr Punktezahl ist {{ quiz.points_gotten }} von {{ quiz.points_max }}.
      Sie haben
      <strong>{{ percent }}%</strong> der Gesamtpunktzahl erreicht.
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'quiz' ],
  computed: {
    percent () {
      return Math.floor( this.quiz.points_gotten / this.quiz.points_max * 100 )
    },
    percentage () {
      return Math.floor( 2.5 * this.percent ) + ' 250'
    }
  }
}
</script>

<style lang="scss" scoped>
.summary {
  margin-bottom: 40px;
  .text {
    font-size: 1rem;
  }
  h1 {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 1.3em;
    // font-weight: bold;
  }
  svg {
    float: left;
    width: 110px;
    height: 110px;
  }
  div {
    margin-bottom: 20px;
  }
  &:after {
    content: " ";
    display: block;
    clear: both;
    height: 0;
  }
}
</style>
