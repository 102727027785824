import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

// Connects to data-controller="handle-related"
export default class extends Controller {
  connect() {
    // console.log('handle-related')
  }
  search(event) {
    // console.log('handle-related#search', event.srcElement.value)
  }
  toggle(event) {
    const source_id = event.srcElement.dataset.sourceId
    const issue_id = event.srcElement.value

    // console.log('handle-related#toggle', event.srcElement.dataset.sourceId)
    // console.log('handle-related#toggle', event.srcElement.value)

    Rails.ajax({
      type: "GET",
      url: `/issues/${source_id}/toggle/${issue_id}`,
    })
  }
}
